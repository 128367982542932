/*------------------------------------*\
    #PAGE REGISTER
\*------------------------------------*/

.additional-kids-wrapper {
  margin-top: 50px;
}

.terms-submit__submit {
  text-align: right;
}

.form {
  +from-width(1200px) {
    margin-bottom: 100px;
  }
}
