/*------------------------------------*\
    #BUTTON
\*------------------------------------*/

.button {
  display: inline-block;
  proxima-nova-bold();
  set-font-size(18px);
  letter-spacing: 0.2rem;
  text-align: center;
  background-color: $colors["primary"].light;
  border: 1px solid $colors["primary"].light;
  transition: background-color 0.3s ease-in-out, letter-spacing 0.3s ease-in-out, color 0.3s ease-in-out;
  text-transform: uppercase;
  padding: 10px 15px;

  &:hover {
    background-color: transparent;
    letter-spacing: 0.1rem;
  }
}

.button--submit {
  color: white;

  &:hover {
    color: $colors["secondary"].darker;
  }
}




.read-more {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: $colors["primary"].base;
  padding-bottom: 5px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  overflow: hidden;
  margin-top: 25px;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    transition: transform 0.3s;
  }

  &:after {
    background: $colors["primary"].base;
    transform: translate3d(-101%,0,0);
  }


  &:before {
    background: $colors["background"].base;
  }

  &:hover {

    &:after {
      transform: translate3d(0,0,0);
    }
  }

}





.register-now {
  proxima-nova-black();
  width: 100%;
  display: block;
  padding: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: $colors["primary"].base;
  background: $colors["background"].base;
  position: relative;
  transition: background-color 0.3s ease-in-out, letter-spacing 0.3s ease-in-out;

  +from-width(769px) {
    margin-top: 45px;
  }

  &:hover {
    background-color: transparent;
    letter-spacing: 0.1rem;
  }
}

.register-now__arrow {
  background-color: $colors["primary"].base;
  background-image: url('../images/svg/right-arrow-black.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px auto;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
}





/*------------------------------------*\
    #BUTTON ADD KID
\*------------------------------------*/

.button-addkid {
	set-font-size(16px);
	background: $colors["primary"].base;
	color: white;
	border-radius: 25px;
	padding: 14px 60px 14px 24px;
	min-width: 218px;
	display: inline-block;
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 48px;
		height: 100%;
		text-align: center;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		background: $colors["primary"].light url('../images/svg/plus.svg') 12px center no-repeat;
		transition: background 0.3s ease-in-out;
	}

	&:hover {
		&:after {
			background-color: $colors["primary"].light;
		}
	}

}

.form .button-addkid:hover {
	color: grayLight;
}





/*------------------------------------*\
    #CLOSE VIDEO
\*------------------------------------*/

.mfp-close {
  opacity: 1;
  font-size: 40px;
}
