/*------------------------------------*\
    #MODULE HERO
\*------------------------------------*/

.hero {
  height: 100%;
  position: relative;
  color: white;
  background-image: url('../images/hero_tablet.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  text-transform: uppercase;


  +from-width(769px) {
    // background-image: url('../images/hero.jpg');
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../images/hero.jpg');
    background-size: cover;
  }
}

.hero__menu-icon {
  +from-width(1200px) {
    display: none;
  }
}

.hero__content {
  position: absolute;
  bottom: 10%;
  left: 5%;
  max-width: 80%;

  +from-width(768px) {
    max-width: 65%;
    bottom: 7%;
  }

}

.hero__title {
  proxima-nova-black();
  color: $colors["primary"].dark;
  display: inline-block;
  set-font-size(35px);
  margin: 0 0 24px 0;

  +from-width(768px) {
    set-font-size(70px);
    margin: 0 0 10px 0;
  }
}


.hero__title--white {
  background: white;
  padding: 0 6px;
  line-height: 1.35;
}

.hero__list {
  proxima-nova-bold();
  list-style: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;

  +from-width(768px) {
    margin: 0 0 30px 0;
  }
}

.hero__list-item {
  set-font-size(12px);
  line-height: 16px;
  letter-spacing: 0.15rem;

  +from-width(768px) {
    set-font-size(20px);
    background-image: url('../images/svg/right-arrow-light-blue.svg');
    background-repeat: no-repeat;
    background-position: left 4px;
    padding-left: 2rem;
    margin-bottom: 20px;
    line-height: inherit;
  }
}

.hero__desc {
  display: none;

  +from-width(768px) {
    display: block;
  }

  +from-width(769px) {
    proxima-nova-bold();
    set-font-size(20px);
    letter-spacing: 0.15rem;
    line-height: 2;
    max-width: 680px;
  }
}

.hero__twitter {
  display: block;
  position: absolute;
  right: 5%;
  top: 7%;
  fill: $colors["social"].twitter;
}

.hero__twitter-icon {
  width: 30px;
  height: 21px;

  +from-width(769px) {
    width: 46px;
    height: 37px;
  }
}

.hero__play {
  position: absolute;
  right: 5%;
  bottom: 9%;
  fill: white;
 
  +from-width(768px) {
    bottom: 7%;
  }
}

.hero__play-icon {
  width: 40px;
  height: 40px;

  +from-width(769px) {
    width: 81px;
    height: 81px;
  }
}
