/*------------------------------------*\
    #MODULE SECTION
\*------------------------------------*/

.section {
  position: relative;
  color: $colors["secondary"].base;
  background-repeat: no-repeat;
  background-color: white;
  overflow: hidden;
  background-size: cover;
  clearfix();

  +from-width(769px) {
    min-height: 1080px;
    background-size: inherit;
  }

  +from-width(1960px) {
    background-size: 50% auto;
  }
}


.section__content {
  padding: 5% 5%;
  position: relative;

  +from-width(769px) {
    height: 800px;
    padding: 2% 5%;
  }

  &.section__content--full {
    padding: 2% 0;
  }
}

.section__content--half {
  background: white;
  width: 100%;
  padding: 0 0 0 0 !important;

  +from-width(769px) {
    width: 50%;
  }
}

.section__content--left {
  +from-width(769px) {
    float: left;
  }
}

.section__content--right {
  +from-width(769px) {
    float: right;
  }
}

.section__title {
  color: $colors["primary"].base;
  proxima-nova-black();
  set-font-size(30px);
  text-transform: uppercase;
  line-height: 1.06;
  margin-top: 0;

  +from-width(769px) {
    set-font-size(58px);
    margin-bottom: 35px;
  }

  +from-width(1300px) {
    set-font-size(66px);
  }
}


.section__list {
  padding: 0 0 0 0;
  list-style: none;
}


.section__list-item {
  set-font-size(16px);
  background-image: url("../images/svg/right-arrow-dark-blue.svg");
  background-repeat: no-repeat;
  background-position: left 4px;
  padding-left: 20px;
  margin-bottom: 20px;
}


.section__desc {
  set-font-size(16px);
}


.section--full {
  background-color: $colors["background"].base;
  padding: 2% 5%;
}


.section__thumbs {
  clearfix();

  // +from-width(769px) {
  //   display: flex;
  // }
}

.section__thumb {
  background: white;
  margin-top: 20px;

  +from-width(769px) {
    column('1/3');
    // flex: 1;
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

}


.thumb__image {
  width: 100%;
  height: auto;
  vertical-align: top;
}


.section__thumb-header {
  position: relative;
}


.section__thumb-footer {
  background-color: white;
  padding: 9%;
}


.section__thumb-title {
  proxima-nova-bold();
  color: $colors["primary"].base;
  text-transform: uppercase;
  margin: 0 0 0 0;
}

.section__thumb-desc {}


.section__content--more {
  position: absolute;
  height: 100%;
  bottom: -100%;
  overflow: hidden;
  padding: 2% 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // z-index: 9;
  // transform: translate3d(0, 100%, 0);
  background: white;
  transition: bottom 0.3s, opacity 0.3s;
  opacity: 0;

  &.is-open {
    // transform: translate3d(0, -100%, 0);
    opacity: 1;
    transition: bottom 0.3s;
    bottom: 0;
    // overflow-y: scroll;

    .test & {
      vertical-align: top;
      height: 100%;
    }
  }
}


.section__close {
  position: absolute;
  top: 0;
  right -30px;
}

.section__close-icon {
  overflow:hidden;
  width: 19px;
  height: 19px;
  fill: $colors["primary"].base;
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    fill: $colors["accent"].base;
    transform: rotate(360deg);
  }
}



.section__mobile {
	width: 100%;
	ks-ratio-box( 9/6 );

	+from-width(769px) {
		display: none;
	}
}



.section__organization {
  margin: 40px 0;
}

.desc__subtitle {
  set-font-size(18px);
  color: $colors["primary"].base;
  text-transform: uppercase;
  margin-bottom: 0;
}

.section__desc {
  set-font-size(16px);
}

.organization__link {
  set-font-size(16px);
  color: $colors["accent"].base;
}

.picture__tag {
  text-transform: uppercase;
  color: $colors["secondary"].darker;
  margin-bottom: 0;
  font-weight: 700;
}

.organization__pictures {
  clearfix();
}

.organization__picture {
  column('1/2', 10px);

  > img {
    width: 100%;
    height: auto;
  }
}



.table {
  display: table;
  height: 100%;
}

.table-cell {
  display: table-cell;

  &.middle {
    vertical-align: middle;
  }
}




.section__content--default {
  height: 100%;
  padding: 5% 10%;
}

.section__content--more {
  padding: 5% 10%;
}

.mCSB_container {
  overflow: visible;
}
