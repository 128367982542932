/*------------------------------------*\
    #REUSABLE
\*------------------------------------*/

.photo-credit {
  set-font-size(11px);
  margin: 0 0 0 0;
  text-transform: initial;
  display: block;

  +from-width(1200px) {
    set-font-size(12px);
  }

  +from-width(1400px) {
    display: block;
    set-font-size(14px);
  }
}

.photo-credit--left,
.photo-credit--right {
  position: absolute;
  bottom: 10px;
}

.photo-credit--left {
  right: 50%;
  transform: translateX(-10%);
}

.photo-credit--right {
  right: 5%;
}

.photo-credit--dark {
  color: $colors["primary"].base;
}

.photo-credit--light {
  color: white;
}

.section-1__photo-credit:after {
  set-font-size(11px);
  color: $colors["primary"].base;
  text-transform: initial;
  display: block;
  content: 'Property of Sidney Crosby Hockey School'
  position: absolute;
  bottom: 10px;
  right: 5%;

  +from-width(769px) {
    display: none;
  }
}

.section-3__photo-credit:after {
  set-font-size(11px);
  color: white;
  text-transform: initial;
  display: block;
  content: 'Photo Courtesy of Reebok-CCM Hockey, Sidney Crosby, Pittsburgh Penguins'
  position: absolute;
  bottom: 10px;
  right: 5%;

  +from-width(769px) {
    display: none;
  }
}

.section-4__photo-credit:after {
  set-font-size(11px);
  color: $colors["primary"].base;
  text-transform: initial;
  display: block;
  content: 'Property of Sidney Crosby Hockey School'
  position: absolute;
  bottom: 10px;
  right: 5%;

  +from-width(769px) {
    display: none;
  }
}




.table {
  display: table;
}

.table-cell {
  display: table-cell;

  &.middle {
    vertical-align: middle;
  }
}
