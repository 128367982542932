/*------------------------------------*\
    #FORMS ELEMENTS
\*------------------------------------*/

input[type="text"],
select,
textarea {
	background: $colors["background"].base;
	color: $colors["secondary"].base;
	border-radius: 3px;
	border: none;
	width: 100%;
	padding: 0 3.8461538462%;


}

input[type="text"],
select {
	height: 40px;

	+from-width(768px) {
		height: 50px;
	}
}


input,
select,
textarea,
button {
	outline: none;
}


select {
	appearance: none;
	-webkit-appearance: none;
	background-image: url('../images/svg/triangle-down.svg');
	background-repeat: no-repeat;
	background-position: 96% center;
	text-indent: 0.01px;
	text-overflow: "";

	&.error {
		border: 1px solid $colors["accent"].base;
	}
}

legend {
	color: $colors["primary"].base;
	border-bottom: 1px solid $colors["background"].base;
	width: 100%;
	proxima-nova-bold();
	text-transform: uppercase;
	margin-bottom: 26px;
	position: relative;
	set-font-size(20px);
	padding-left: 15px;
	padding-bottom: 24px;

	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		width: 4px;
		height: 14px;
		background-color: $colors["primary"].base;
		top: 4px;
		left: 0px;
	}
}

fieldset {
	border: none;
	margin-bottom: 50px;
	padding: 0;
}

label {
	color: $colors["secondary"].darker;
	display: block;

	+from-width(768px) {
		margin-bottom: 10px;
	}
}

textarea {
	padding: 15px;
	height: 150px;
	resize: none;

	&.error {
		border: 1px solid $colors["accent"].base;
	}
}

.select-preview {
	display: none;
}



/*------------------------------------*\
    #FORMS ERRORS
\*------------------------------------*/

input {
	&.error {
		border: 1px solid $colors["accent"].base;
	}
}


.error-message {
	color: $colors["accent"].base;
	display: block;
	margin: 15px 0px 20px;
}

.error-zone {
	color: $colors["accent"].base;
}
