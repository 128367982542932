/*------------------------------------*\
    #PAGE SUBPAGE
\*------------------------------------*/


.subpage-header{
  padding: 3% 5%;
}

.subpage {
  padding: 0% 5% 5% 5%;
  color: $colors["secondary"].base;
}

.subpage__title,
.subpage__subtitle {
  proxima-nova-bold();
  color: $colors["primary"].base;
  text-transform: uppercase;
}


.subpage-header__title {
  color: $colors["primary"].base;
  proxima-nova-black();
  set-font-size(30px);
  text-transform: uppercase;
  line-height: 1.06;
  margin: 0;

  +from-width(769px) {
    set-font-size(66px);
  }
}
