/*------------------------------------*\
    #MODULE FOOTER
\*------------------------------------*/

.footer {
  background-color: $colors["background"].base;
  color: $colors["secondary"].darker;
  padding: 2% 5%;
}



.footer {

  .page-home & {
    position: relative;
  }

  +from-width(1200px) {
      position: absolute;
      bottom: 0;
      right: inherit;
  }
}

.l-subpage {
  +from-width(1200px) {
      padding-bottom: 90px;
      position: relative;
      min-height: 100%;
  }
}


.footer__copyright,
.footer__nav {
  color: $colors["primary"].base;

  +from-width(769px) {
    display: inline-block;
    vertical-align: top;
    margin-top: 16px;
    width: calc(50% - 0.6px);
  }

}

.footer__copyright {
  text-align: center;

  +from-width(769px) {
    text-align: left;
  }
}

.footer__nav {
  text-align: center;

  +from-width(769px) {
    text-align: right;
  }
}

.footer__nav-item {
  transition: color 0.3s ease-in-out;
  display: block;

  +from-width(819px) {
    padding: 0 0 0 20px;
  }

  &:hover {
    color: $colors["accent"].base;
  }

  &:first-child {
    padding-left: 0;
  }

  +from-width(769px) {
    display: inline-block;
  }
}

.footer__nav-border {

}

.footer__desc {
  margin: 0 0 0 0;
  padding-bottom: 20px;
  border-bottom: 1px solid $colors["secondary"].light;
}
