/*------------------------------------*\
    #LAYOUT FORMS
\*------------------------------------*/

.form-row {
	clearfix();
	margin-bottom: 15px;

	.form-group {
		&:first-child {
			margin-bottom: 15px;

			+from-width(480px) {
				margin-bottom: 0;
			}
		}
	}
}

.form-group {

	+from-width(480px) {
		column('1/2');
	}
}
