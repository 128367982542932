/*------------------------------------*\
    #BASE
\*------------------------------------*/
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  proxima-nova();

  font-size: $base-font-size;
  line-height: $base-line-height;

  font-size: 75%;

  +from-width(769px) {
    font-size: 80%;
  }

  +from-width(992px) {
    font-size: 90%;
  }

  +from-width(1200px) {
    font-size: 100%;
  }



}

html, body {
	height: 100%;
	background: #fff;
  margin: 0 auto;
  padding: 0;
  border: 0;
	text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}
