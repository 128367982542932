/*------------------------------------*\
    #GLOBAL LAYOUT
\*------------------------------------*/

.l-wrapper {
  width: 100%;
  // max-width: 1920px;
  // margin: 0 auto;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.l-content {
  height: 100%;

  +from-width(1200px) {
    padding: 0 0 0 21.09375%;
  }
}
