/*******************************************************************************
GRID SETTINGS
*******************************************************************************/
// Lost Grid v5.1.6 - https://github.com/corysimmons/lost
$gutter = 30px;
$rtl = false;





/*******************************************************************************
COLOR SETTINGS
*******************************************************************************/
// Arrays of color call your color like this $colors["primary"].dark;
$colors = {
  primary: {
    base: #384a75,
    light: #00aced,
    dark: #1f2942
  },
  accent: {
    base: #e92735
  },
  secondary: {
    base: #797979,
    light: #d7dadf,
    darker: #737373
  },
  background: {
    base: #ebedf1
  },
  social: {
    twitter: #1dcaff
  }
}

//#e92735 red



/*******************************************************************************
FONT SETTINGS
*******************************************************************************/

proxima-nova() {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
}

proxima-nova-bold() {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
}

proxima-nova-black() {
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
}





/* Base sizes */
$base-font-size             = 16px; /* browser default */
$base-line-height           = $base-font-size * 1.5;
