/*------------------------------------*\
    #PAGE HOME
\*------------------------------------*/


.main {
  height: 100%;
  // position: relative;
  // padding: 0 0 0 21.09375%;
}


.section {
  background-position: center top;
}

.section-1 {
  background-image: url('../images/section1_bg.jpg');

  +from-width(769px) {
    background-position: left top;
  }
}

.section-3 {
  /*background-image: url('../images/section3_bg.jpg');
  height: 500px;*/
  min-height: inherit !important; 

  +from-width(769px) {
    background-position: -120px top;
  }
  .section__content--half {
    height: 650px;
  }
}


.section-4 {
  background-image: url('../images/section4_bg.jpg');

  +from-width(769px) {
    background-position: left top;
  }
}
