/*------------------------------------*\
    #MODULE ASIDE
\*------------------------------------*/

.aside {
  background-color: $colors["primary"].dark;
  color: white;
  height: 100%;

  +from-width(1200px) {
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    // width: 21.06%;
    // max-width: 405px;
  }

}


.hero__menu {
  color: $colors["primary"].base;
  set-font-size(20px);
  vertical-align: top;
  margin: 25px 0 12px 5%;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  background-image: url('../images/svg/menu-blue.svg');
  background-repeat: no-repeat;
  background-position: center right;


  background-size: 18px 18px;
  padding-right: 24px;


  +from-width(769px) {
    background-size: 19px 19px;
  }

  +from-width(992px) {
    padding-right: 28px;
    background-size: 20px 20px;
  }


  +from-width(1200px) {
    display: none;
  }

  .page-home & {
    background-image: url('../images/svg/menu-white.svg');
    color: white;
    position: absolute;
    left: 5%;
    top: 7%;
    margin: 0;
  }
}


.sb-slidebar.sb-left {
  +from-width(1200px) {
    display: block;
    z-index: 9;
    width: 21.09375%;
  }
}


.aside__logo {
  width: 100%;
  display: block;
  background-color: $colors["primary"].base;
  background-image: url('../images/svg/logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  ks-ratio-box( 2/1 );
  background-size: 40% auto;

  +from-width(768px) {
    ks-ratio-box( 1 );
    background-size: 75% 75%;
  }


}


.aside__nav {
  proxima-nova-bold();
  padding: 10% 14.567901235% 0 14.567901235%;
  text-transform: uppercase;
  display: block;
}


.aside__nav-item {
  position: relative;
  padding-left: 14px;
  margin-bottom: 14px;
  display: block;
  set-font-size(16px);
  transition: color 0.3s ease-in-out;

  +from-width(1400px) {
    set-font-size(19px);
    margin-bottom: 25px;
  }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 4px;
    height: 12px;
    background-color: white;
    top: 6px;
    left: 0px;
    transition: background-color 0.3s ease-in-out;
  }

  &:hover {
    color: $colors["accent"].base;

    &:before {
      background-color: $colors["accent"].base;
    }
  }

  &.is-selected {
    color: $colors["accent"].base;

    &:before {
      background-color: $colors["accent"].base;
    }
  }

}


.aside__button {
  position: absolute;
  bottom: 5%;
  left: 14.567901235%;
  right: 14.567901235%;
  set-font-size(12px);
  padding: 10px 15px;

  +from-width(768px) {
    set-font-size(18px);
  }


  +from-width(1200px) {
    set-font-size(14px);
  }

  +from-width(1350px) {
    set-font-size(18px);
    padding: 18px 15px;
  }
}
